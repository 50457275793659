import "./App.css";

function App() {
  return (
    <div data-spy="scroll" data-target=".navbar-nav" data-offset="90">      
      <header className="cursor-light">
        <nav className="navbar navbar-top-default navbar-expand-lg nav-three-circles black bottom-nav nav-box-shadow no-animation">
          <div className="container-fluid">
            <div className="logo ml-lg-1" href="/">
              <img
                src="assets/img/logo.svg"
                alt="logo"
                title="Logo"
                className="logo-default"
              />
              <img
                src="assets/img/logo2.svg"
                alt="logo"
                title="Logo"
                className="logo-scrolled"
              />
            </div>
            <div className="collapse navbar-collapse d-none d-lg-block">
              <ul className="nav navbar-nav mx-auto">
                <li className="nav-item">                  
                  <a href="#home" className="scroll nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#about" className="scroll nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#services" className="scroll nav-link">
                    Services
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="/" className="nav-link">
                    Estimate my app
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#clients" className="scroll nav-link">
                    Clients
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#blog" className="scroll nav-link">
                    Blog
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#contact" className="scroll nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <a href="https://app.codeflex.co.za" className="btn-setting color-black btn-hvr-up bg-dark-primary btn-hvr-pink text-white link wow fadeInUp cursor"><i className="fa fa-user mr-2"></i>Client zone</a>

            <a className="menu_bars d-inline-block menu-bars-setting animated-wrap sidemenu_toggle d-block d-lg-none">
              <div className="menu-lines animated-element">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </nav>
        <div className="cf-logo">
          <img
            src="assets/img/logo.svg"
            alt="logo"
            title="Logo"
            className="logo-default"
          />
        </div>
        <a className="menu_bars menu-bars-setting animated-wrap sidemenu_toggle d-lg-inline-block d-none">
          <div className="menu-lines animated-element">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>

        <div className="side-menu center">
          <div className="quarter-circle">
            <div className="menu_bars2 active" id="btn_sideNavClose">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="inner-wrapper justify-content-center">
            <div className="col-md-12 text-center">
              <a href="/" className="logo-full mb-4">
                <img src="assets/img/logo-pure-white.svg" alt="" />
              </a>
            </div>
            <nav className="side-nav m-0">
              <ul className="navbar-nav flex-lg-row">
                <li className="nav-item">                  
                  <a href="#home" className="scroll nav-link active">
                    Home
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#about" className="scroll nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#services" className="scroll nav-link">
                    Services
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="/" className="nav-link">
                    Estimate My App
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#clients" className="scroll nav-link">
                    Clients
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#blog" className="scroll nav-link">
                    Blog
                  </a>
                </li>
                <li className="nav-item">                  
                  <a href="#contact" className="scroll nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>

            <div className="side-footer text-white w-100">
              <ul className="social-icons-simple">
                <li className="side-menu-icons">
                  <a href="/">
                    <i className="fab fa-facebook-f color-white"></i>{" "}
                  </a>{" "}
                </li>
                <li className="side-menu-icons">
                  <a href="/">
                    <i className="fab fa-instagram color-white"></i>{" "}
                  </a>{" "}
                </li>
                <li className="side-menu-icons">
                  <a href="/">
                    <i className="fab fa-twitter color-white"></i>{" "}
                  </a>{" "}
                </li>
              </ul>
              <p className="text-white">
                &copy; 2023 CodeFlex.
              </p>
            </div>
          </div>
        </div>
        <a id="close_side_menu" href="/"> </a>
      </header>

      <section id="home" className="p-0 h-100vh cursor-light">
        <h2 className="d-none">CodeFlex</h2>

        <div
          id="rev_slider_8_1_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="weone"
          data-source="gallery"
          style={{ background: "transparent", padding: "0px" }}
        >
          <div
            id="rev_slider_8_1"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.4.8.1"
          >
            <ul>
              <li
                data-index="rs-36"
                data-transition="fade"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="300"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                <div
                  className="tp-caption color-yellow creative"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['270','200','100','55']"
                  data-fontsize="['60','50','40','40']"
                  data-lineheight="['75','75','75','40']"
                  data-width="['656','656','656','300']"
                  data-height="['none','none','none','87']"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"delay":100,"split":"chars","splitdelay":0.1,"speed":1480,"split_direction":"random","frame":"0","from":"y:50px;sX:1;sY:1;opacity:0;fb:20px;","color":"rgba(0,0,0,0)","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","color":"transparent","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  Elevate with codeflex
                </div>

                <div
                  className="tp-caption color-black agency"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','top','top','top']"
                  data-voffset="['348','260','155','98']"
                  data-fontsize="['60','50','40','40']"
                  data-lineheight="['75','75','45','45']"
                  data-width="['656','656','656','350']"
                  data-height="['none','none','none','87']"
                  data-whitespace="nowrap"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"delay":150,"speed":1500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div id="js-rotating">
                    Crafting Optimal concepts, Software Development, Web
                    Development, Mobile App Development, Designs and Branding,
                    Marketing Campaigns, Digital Experiences, Future-Proof IT
                    Solutions
                  </div>{" "}
                </div>

                <div
                  className="tp-caption color-black hero-text"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['top','middle','middle','middle']"
                  data-voffset="['460','95','0','10']"
                  data-whitespace="normal"
                  data-width="['630','630','550','440']"
                  data-fontsize="['16','15','15','15']"
                  data-lineheight="['25','25','22','22']"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"delay":100,"speed":1480,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  At CodeFlex, we craft digital solutions that transcend
                  possibilities. With cutting-edge technology, expert
                  developers, and a passion for innovation, we're your trusted
                  partner in the world of software development.
                </div>

                <div
                  className="tp-caption tp-resizeme learn-more"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['170','200','90','120']"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1000,"to":"o:1;","delay":800,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"},{"frame":"hover","speed":"300","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);bw:2px 2px 2px 2px;"}]'
                  data-textalign="['center','center','center','center']"
                  data-width="['160','160','160','160']"
                >
                  <a
                    href="https://app.codeflex.co.za"
                    className="btn-setting color-black btn-hvr-up bg-dark-primary btn-hvr-pink text-white link wow fadeInUp cursor"
                  >
                    Start your project
                  </a>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['center','center','center','center']"
                  data-hoffset="['-20','-20','-20','-20']"
                  data-y="['top','top','top','top']"
                  data-voffset="['100','0','-150','-150']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:bottom;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="12"
                    data-xs="0"
                    data-xe="60"
                    data-ys="100"
                    data-ye="60"
                  >
                    <img
                      src="assets/img/slider-shape1.png"
                      alt=""
                      data-ww="['51px','51px','51px','51px']"
                      data-hh="['52px','52px','52px','52px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['right','right','right','right']"
                  data-hoffset="['150','150','30','30']"
                  data-y="['top','top','top','top']"
                  data-voffset="['160','80','-100','-100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:middle;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="12"
                    data-xs="0"
                    data-xe="-50"
                    data-ys="50"
                    data-ye="20"
                  >
                    <img
                      src="assets/img/slider-shape2.png"
                      alt=""
                      data-ww="['50px','50px','50px','50px']"
                      data-hh="['14px','14px','14px','14px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['250','80','80','30']"
                  data-y="['top','top','top','top']"
                  data-voffset="['227','-16','-16','-36']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:right;y:bottom;rZ:360deg;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-rotate"
                    data-easing="Power0.easeIn"
                    data-startdeg="0"
                    data-enddeg="360"
                    data-speed="15"
                    data-origin="50% 50%"
                  >
                    <img
                      src="assets/img/slider-shape3.png"
                      alt=""
                      data-ww="['67px','67px','67px','67px']"
                      data-hh="['69px','69px','69px','69px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption z-index-2"
                  data-x="['right','right','right','right']"
                  data-hoffset="['300','200','100','100']"
                  data-y="['bottom','bottom','center','center']"
                  data-voffset="['200','50','200','200']"
                  data-whitespace="normal"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1490,"frame":"0","from":"x:center;y:top;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="12"
                    data-angle="90"
                    data-radius="22px"
                    data-origin="50% 50%"
                  >
                    <div className="animated-wrap slider-social icon-small">
                      <div className="animated-element icon-box-small bg-gray-two pinterest-bg-hvr d-flex justify-content-center align-items-center">
                        <a className="pinterest-text-hvr" href="/">
                          <i
                            className="fab fa-youtube font-16"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tp-caption z-index-2"
                  data-x="['left','left','left','left']"
                  data-hoffset="['350','350','120','120']"
                  data-y="['bottom','bottom','center','center']"
                  data-voffset="['80','0','200','200']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1490,"frame":"0","from":"y:center;x:bottom;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="12"
                    data-angle="-90"
                    data-radius="22px"
                    data-origin="-50% -50%"
                  >
                    <div className="animated-wrap slider-social icon-small">
                      <div className="animated-element icon-box-small bg-pink-two instagram-bg-hvr d-flex justify-content-center align-items-center">
                        <a className="instagram-text-hvr" href="/">
                          <i
                            className="fab fa-instagram font-16"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tp-caption z-index-2"
                  data-x="['right','right','right','right']"
                  data-hoffset="['-110','-20','0','0']"
                  data-y="['middle','middle','bottom','bottom']"
                  data-voffset="['200','300','50','10']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1490,"frame":"0","from":"x:center;y:top;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="19"
                    data-angle="45"
                    data-radius="22px"
                    data-origin="50% 50%"
                  >
                    <div className="animated-wrap slider-social icon-small">
                      <div className="animated-element icon-box-large-two bg-yellow twitter-bg-hvr d-flex justify-content-center align-items-center">
                        <a className="twitter-text-hvr" href="/">
                          <i
                            className="fab fa-twitter font-20"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tp-caption z-index-2"
                  data-x="['left','left','left','left']"
                  data-hoffset="['-250','-20','0','0']"
                  data-y="['middle','middle','bottom','bottom']"
                  data-voffset="['70','70','100','100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1490,"frame":"0","from":"y:center;x:bottom;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="12"
                    data-xs="0"
                    data-xe="0"
                    data-ys="-10"
                    data-ye="60"
                  >
                    <div className="animated-wrap slider-social icon-small">
                      <div className="animated-element icon-box-large bg-pink facebook-bg-hvr d-flex justify-content-center align-items-center">
                        <a className="facebook-text-hvr" href="/">
                          <i
                            className="fab fa-facebook-f font-20"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['right','right','right','right']"
                  data-hoffset="['-200','-200','-5000','-5000']"
                  data-y="['bottom','bottom','bottom','bottom']"
                  data-voffset="['150','150','150','150']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:middle;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="12"
                    data-xs="0"
                    data-xe="0"
                    data-ys="10"
                    data-ye="-50"
                  >
                    <img
                      src="assets/img/slider-shape4.png"
                      alt=""
                      data-ww="['24px','24px','24px','24px']"
                      data-hh="['87px','87px','87px','87px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['83','66','20','20']"
                  data-y="['bottom','bottom','bottom','bottom']"
                  data-voffset="['200','80','-100','-100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:middle;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="12"
                    data-ys="-40"
                    data-ye="40"
                    data-xs="0"
                    data-xe="20"
                  >
                    <img
                      src="assets/img/slider-shape5.png"
                      alt=""
                      data-ww="['44px','44px','44px','44px']"
                      data-hh="['87px','87px','87px','87px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1"
                  data-x="['right','right','right','right']"
                  data-hoffset="['150','150','50','50']"
                  data-y="['top','top','top','top']"
                  data-voffset="['70','-70','-130','-130']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:middle;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="12"
                    data-angle="90"
                    data-radius="12px"
                    data-origin="0% 0%"
                  >
                    <img
                      src="assets/img/slider-shape6.png"
                      alt=""
                      data-ww="['44px','44px','44px','44px']"
                      data-hh="['87px','87px','87px','87px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption z-index-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['-500','-500','5000','5000']"
                  data-y="['top','top','top','top']"
                  data-voffset="['-100','-100','0','0']"
                  data-whitespace="nowrap"
                  data-width="['394px','394px','394px','394px']"
                  data-height="['393px','393px','393px','393px']"
                  data-responsive_offset="on"
                  data-frames='[{"delay":319.921875,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-rotate"
                    data-easing="Power0.easeIn"
                    data-speed="150"
                    data-startdeg="0"
                    data-enddeg="360"
                    data-origin="45% 45%"
                  >
                    <img
                      src="assets/img/circle-top-left.png"
                      alt=""
                      data-ww="['394px','394px','394px','394px']"
                      data-hh="['394px','394px','394px','394px']"
                      data-no-retina
                    />
                  </div>
                </div>

                <div
                  className="tp-caption z-index-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['1180','1180','5000','5000']"
                  data-y="['top','top','top','top']"
                  data-voffset="['-200','-200','-200','-200']"
                  data-width="['575px','575px','575px','575px']"
                  data-height="['571px','571px','571px','571px']"
                  data-frames='[{"delay":319.921875,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="6"
                    data-angle="0"
                    data-radius="5px"
                    data-origin="50% 50%"
                  >
                    <img
                      src="assets/img/circle-top-right-inner.png"
                      alt=""
                      data-ww="['575px','575px','575px','575px']"
                      data-hh="['571px','571px','571px','571px']"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tp-caption z-index-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['1280','1280','5000','5000']"
                  data-y="['top','top','top','top']"
                  data-voffset="['-110','-110','-110','-110']"
                  data-width="['381px','381px','381px','381px']"
                  data-height="['380px','380px','380px','380px']"
                  data-frames='[{"delay":319.921875,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped"
                    data-easing="Power0.easeInOut"
                    data-speed="0"
                    data-xs="0"
                    data-xe="0"
                    data-ys="0"
                    data-ye="0"
                  >
                    <img
                      src="assets/img/circle-top-right.png"
                      alt=""
                      data-ww="['381px','381px','381px','381px']"
                      data-hh="['380px','380px','380px','380px']"
                      data-no-retina
                    />{" "}
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme rs-parallaxlevel-8 z-index-1"
                  data-x="['left','left','center','center']"
                  data-hoffset="['300','300','-5000','-5000']"
                  data-y="['top','top','middle,'middle']"
                  data-voffset="['50','50','0','0']"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":319.921875,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                >
                  <div
                    className="rs-looped rs-wave"
                    data-speed="15"
                    data-angle="90"
                    data-radius="12px"
                    data-origin="50% 50%"
                  >
                    <img
                      src="assets/img/circle-center.png"
                      alt=""
                      data-ww="['672px','672px','500px','320px']"
                      data-hh="['673px','673px','500px','320px']"
                      data-no-retina
                    />{" "}
                  </div>
                </div>

                <div
                  className="tp-caption tp-resizeme z-index-1 cf-arrows"
                  data-x="['left','left','left','left']"
                  data-hoffset="['30','30','-20','-100']"
                  data-y="['top','top','top','top']"
                  data-voffset="['50','50','50','50']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="image"
                  data-responsive_offset="on"
                  data-frames='[{"delay":50,"speed":1480,"frame":"0","from":"x:center;y:middle;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":350,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeInOut"
                    data-speed="30"
                    data-xs="-90"
                    data-xe="90"
                    data-ys="0"
                    data-ye="20"
                  >
                    <img
                      src="assets/img/arrows.png"
                      alt=""
                      data-ww="['97px','97px','97px','97px']"
                      data-hh="['98px','98px','98px','98px']"
                      data-no-retina
                    />{" "}
                  </div>
                </div>
              </li>
            </ul>
            <div className="tp-bannertimer tp-banner-custom"></div>{" "}
          </div>
        </div>
      </section>

      <section className="about pb-0 overflow-visible" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pr-5 mb-5 mb-lg-0 wow fadeInLeft">
              <div className="rare-box"></div>
              <img
                src="assets/img/about.jpg"
                className="about-img-small position-relative w-100"
                alt=""
              />
            </div>
            <div className="col-lg-6 pl-6">
              <div
                className="main-title text-lg-left offset-md-1 mb-0 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <h5 className="wow fadeInUp" data-wow-delay="300ms">
                  {" "}
                  About codeflex{" "}
                </h5>
                <h2
                  className="wow fadeInUp font-weight-light"
                  data-wow-delay="400ms"
                >
                  {" "}
                  Let us take your <span className="color-pink">
                    concept
                  </span>{" "}
                  online
                </h2>
                <p className="pb-4 wow fadeInUp" data-wow-delay="500ms">
                  CodeFlex is more than a software development company; we're a
                  team of visionaries, problem solvers, and innovators. With a
                  commitment to quality and a passion for technology, we've been
                  transforming digital landscapes since 2009.
                </p>

                <ul
                  className="pb-5 text-left wow fadeInUp"
                  data-wow-delay="600ms"
                >
                  <li>You have an idea for a digital experience.</li>
                  <li>
                    We have the product design and engineering expertise to
                    bring it to life.
                  </li>
                  <li>
                    we value the best technologies, tools and processes to
                    deliver successful projects.
                  </li>
                </ul>

                <a
                  href="/"
                  className="btn-setting color-black btn-hvr-up btn-yellow btn-hvr-pink text-white link wow fadeInUp"
                  data-wow-delay="700ms"
                >
                  Estimate My App
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title wow fadeIn" data-wow-delay="300ms">
                <h2>
                  <span className="color-pink">Unlocking</span> your Innovation{" "}
                  <br />
                  Through <span className="color-blue">code</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
              <div className="about-box mb-5 mb-lg-0 mx-auto bg-blue">
                <span className="pro-step d-inline-block color-white">
                  <i className="fa fa-pencil-alt"></i>
                </span>
                <h5 className="font-weight-500 color-white mt-25px mb-15px text-capitalize">
                  Website Development
                </h5>
                <p className="font-weight-light color-white">
                  Transform your ideas into stunning, functional websites that
                  captivate your audience and drive results. Our web development
                  experts create responsive and user-friendly sites that stand
                  out in a crowded digital landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
              <div className="about-box mb-5 mb-lg-0 mx-auto bg-yellow">
                <span className="pro-step d-inline-block color-white">
                  <i className="fa fa-cog"></i>
                </span>
                <h5 className="font-weight-500 color-white mt-25px mb-15px">
                  Mobile App Development
                </h5>
                <p className="font-weight-light color-white">
                  In the mobile-first world, we design and develop iOS and
                  Android apps that engage, delight, and deliver exceptional
                  user experiences. Let us turn your app vision into reality.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-xl-6 col-xxl-6 mb-3">
              <div className="about-box mb-0 mx-auto bg-pink">
                <span className="pro-step d-inline-block color-white">
                  <i className="fa fa-code"></i>
                </span>
                <h5 className="font-weight-500 color-white mt-25px mb-15px text-capitalize">
                  Software Development
                </h5>
                <p className="font-weight-light color-white">
                  Navigate complex tech challenges with our expert advice. We'll
                  guide you through every stage of your project, from planning
                  and strategy to execution and maintenance.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-xl-6 col-xxl-6 mb-3">
              <div className="about-box mb-0 mx-auto bg-dark-primary">
                <span className="pro-step d-inline-block color-white">
                  <i className="fa fa-code"></i>
                </span>
                <h5 className="font-weight-500 color-white mt-25px mb-15px text-capitalize">
                  Design and Branding
                </h5>
                <p className="font-weight-light color-white">
                  Crafting Your Unique Identity Let our creative designers
                  breathe life into your brand. From logos to user interfaces,
                  we ensure your digital identity shines.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title wow fadeIn" data-wow-delay="300ms">
                <h2 className="mb-3">
                  Meet the Brilliant <span className="color-pink">Minds</span>{" "}
                  Behind Our Success{" "}
                </h2>
                <h6>
                  {" "}
                  Our diverse and talented team of developers, designers, and
                  strategists collaborate to bring your visions to life.
                </h6>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp team" data-wow-delay="300">
         
            <div className="col-lg-6 col-xl-6 p-2">
              <div className="card bg-transparent border-0 h-100">
                <div className="card-body">
                  <div className="testimonial-item">
                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img
                          src="assets/img/team1.jpg"
                          alt="team1"
                          className="post"
                        />
                      </a>
                      <div className="text-content">
                        <h3 className="mb-3 text-capitalize color-black">
                          Sabelo Dlamini
                        </h3>
                        <h5 className="mb-3 text-capitalize color-yellow">
                          Chief Executive Officer
                        </h5>
                        <p className="mt-3 mb-3 color-primary-dark">
                          “There is always more to success than what’s meets the
                          eye. Systems can be as good as their planning,
                          infrastructure and resources invested in it.“
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-6 p-2">
              <div className="card bg-transparent border-0 h-100">
                <div className="card-body">
                  <div className="testimonial-item">
                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img
                          src="assets/img/team3.jpg"
                          alt="team1"
                          className="post"
                        />
                      </a>
                      <div className="text-content">
                        <h3 className="mb-3 text-capitalize color-black">
                          Scelo Mbokazi
                        </h3>
                        <h5 className="mb-3 text-capitalize color-yellow">
                          SOLUTION ARCHITECURE
                        </h5>
                        <p className="mt-3 mb-3 color-primary-dark">
                          “In pursuit of what could be next. Greatest measure of
                          work is to work without measure. Hard work has no
                          alternative “
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

               <div className="col-lg-6 col-xl-6 p-2">
              <div className="card bg-transparent border-0 h-100">
                <div className="card-body">
                  <div className="testimonial-item">
                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img
                          src="assets/img/team2.jpg"
                          alt="team1"
                          className="post"
                        />
                      </a>
                      <div className="text-content">
                        <h3 className="mb-3 text-capitalize color-black">
                          Marvin Saunders
                        </h3>
                        <h5 className="mb-3 text-capitalize color-yellow">
                          CONCEPT/BUSINESS DEVELOPER
                        </h5>
                        <p className="mt-3 mb-3 color-primary-dark">
                          “There is always more to success than what’s meets the
                          eye. Systems can be as good as their planning,
                          infrastructure and resources invested in it.“
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-6 p-2">
              <div className="card bg-transparent border-0 h-100">
                <div className="card-body">
                  <div className="testimonial-item">
                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img
                          src="assets/img/team4.jpg"
                          alt="team1"
                          className="post"
                        />
                      </a>
                      <div className="text-content">
                        <h3 className="mb-3 text-capitalize color-black">
                          Thulani Malaza
                        </h3>
                        <h5 className="mb-3 text-capitalize color-yellow">
                          Creative Lead
                        </h5>
                        <p className="mt-3 mb-3 color-primary-dark">
                          “The journey between idea and final product is the
                      greatest journey ever and nothing can be more fulfilling.
                      I love what I do. “
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </section>

      <section id="clients" className="bg-white p-0 cursor-light no-transition">
        <h2 className="d-none">heading</h2>
        <div className="section-padding parallax-setting parallaxie parallax2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-title wow fadeIn" data-wow-delay="300ms">
                  <h5> What our valuable</h5>
                  <h2 className="mb-0">
                    <span className="color-pink"> clients </span>
                    had to say!
                  </h2>
                </div>
              </div>
            </div>

            <div className="testimonial-images">
              <div className="owl-thumbs owl-dots d-lg-block d-none">
                <div className="owl-dot animated-wrap active">
                  <img
                    src="assets/img/testimonial1.png"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial2.png"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial3.png"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial4.png"
                    alt=""
                    className="animated-element"
                  />
                </div>

                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial5.png"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial6.png"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial7.jpg"
                    alt=""
                    className="animated-element"
                  />
                </div>
                <div className="owl-dot animated-wrap">
                  <img
                    src="assets/img/testimonial8.jpg"
                    alt=""
                    className="animated-element"
                  />
                </div>
              </div>
            </div>

            <div className="row align-items-center position-relative">
              <div className="col-md-12">
                <div
                  className="owl-carousel owl-theme testimonial-two hide-cursor mx-auto wow zoomIn"
                  data-wow-delay="400ms"
                >
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      CodeFlex's expertise and dedication have been instrumental in taking our projects to the next level. Their team of developers and designers consistently exceeds our expectations. They are more than a service provider; they're a true partner in our success.{" "}
                    </p>
                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial1.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          David Walker
                        </h5>
                        <p className="color-grey"> CEO of Tech Innovations Inc</p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item hide-cursor">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      We engaged CodeFlex to revamp our digital presence and run marketing campaigns. Their designers delivered eye-catching visuals, and the marketing team drove impressive results. Their holistic approach and attention to detail are unmatched.{" "}
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial2.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Christina Williams
                        </h5>
                        <p className="color-grey"> CMO of GlobalGrowth Corp</p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      CodeFlex was the backbone of my startup journey. Their developers turned my idea into a functional app, and their designers crafted a beautiful user interface. The result? An app that's user-friendly, innovative, and a hit in the market.{" "}
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial3.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Sarah Jones
                        </h5>
                        <p className="color-grey"> Startup Founder </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      We've trusted CodeFlex with our SEO and marketing campaigns for years. Their expertise has significantly boosted our online presence and sales. We appreciate their data-driven approach and the results they consistently deliver.
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial4.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Chris Gorgano
                        </h5>
                        <p className="color-grey"> Marketing Director at E-Store Emporium </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      CodeFlex played a pivotal role in developing our healthcare app. Their commitment to understanding our industry's complexities and designing a user-friendly solution was remarkable. They're more than a vendor; they're part of our team.
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial5.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Kate Carter
                        </h5>
                        <p className="color-grey"> Healthcare Solutions Ltd. </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      CodeFlex's work on our e-commerce platform was transformational. Their development team improved our site's performance, leading to a 200% increase in sales. We're grateful for their technical prowess and dedication.
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial6.png" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Alex Curtis{" "}
                        </h5>
                        <p className="color-grey"> Founder of EcomEmpire </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      Starting a tech company can be daunting, but CodeFlex made the process seamless. Their project management, communication, and development skills are top-notch. I now have a tech product I'm proud of, thanks to them.
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial7.jpg" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                          Ashley Wilson
                        </h5>
                        <p className="color-grey"> Tech Startup Enthusiast </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className="color-black testimonial-para mb-15px">
                      {" "}
                      As a non-profit, we needed cost-effective solutions for our website and digital presence. CodeFlex not only delivered a beautifully designed site but also offered us a tailored package within our budget. Their support for our mission is greatly appreciated.
                    </p>

                    <div className="testimonial-post">
                      <a href="/" className="post">
                        <img src="assets/img/testimonial8.jpg" alt="" />
                      </a>
                      <div className="text-content">
                        <h5 className="color-black text-capitalize">
                        Non-Profit Organization
                        </h5>
                        <p className="color-grey"> Athlete </p>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog" className="half-section p-0 bg-change bg-yellow">
        <h2 className="d-none">heading</h2>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="hover-effect">
                <img
                  alt="blog"
                  src="assets/img/split-blog.jpg"
                  className="about-img"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-lg-0">
              <div className="split-container-setting style-three text-center">
                <div
                  className="main-title mb-5 wow fadeIn"
                  data-wow-delay="300ms"
                >
                  <h5 className="font-18 text-blue"> Oct 12, 2023</h5>
                  <h2 className="mb-0 font-weight-normal"> Our Blog </h2>
                </div>
                <p className="color-black mb-5">
                  Unlocking the Power of AI in E-Commerce: A Comprehensive Guide
                  Discover how artificial intelligence is reshaping the e-commerce industry and how you can leverage it to boost sales and customer satisfaction.
                </p>

                <a
                  href="assets/blog.html"
                  className="btn-setting color-black btn-hvr-up btn-blue btn-hvr-pink"
                >
                  learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="bg-light-gray">
        <div className="container">
          <div className="row mx-lg-0">
            <div className="col-lg-6 col-md-6 col-sm-12 p-0">
              <div className="contact-box">
                <div className="main-title text-center text-md-left mb-4">
                  <h2 className="font-weight-normal">Connect With Us </h2>
                </div>

                <div className="text-center text-md-left">
                  <p className="mb-3">
                  Your Success Is Our Priority
                  </p>
                  <p className="mb-3">                  
                    Ready to discuss your project or have questions? Reach out to our team for a consultation. We're here to help you succeed in the digital world.
                  </p>
                  <p className="mb-3">
                    {" "}
                    Inquiries:{" "}
                    <a href="mailto:client@codeflex.co.za" className="color-black">
                      client@codeflex.co.za
                    </a>{" "}
                    <br />
                    Support:{" "}
                    <a href="mailto:support@codeflex.co.za" className="color-black">
                      support@codeflex.co.za
                    </a>{" "}
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 p-0 col-map box-shadow-map">
              <div id="google-map" className="bg-light-gray map"></div>
            </div>
          </div>
        </div>
      </section>

      <footer className="p-half bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <ul className="footer-icons mb-4">
                <li>
                  <a href="/" className="wow fadeInUp facebook">
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="/" className="wow fadeInDown twitter">
                    <i className="fab fa-twitter"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="/" className="wow fadeInUp google">
                    <i className="fab fa-google"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="/" className="wow fadeInDown linkedin">
                    <i className="fab fa-linkedin-in"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="/" className="wow fadeInUp instagram">
                    <i className="fab fa-instagram"></i>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="/" className="wow fadeInDown pinterest">
                    <i className="fab fa-pinterest-p"></i>{" "}
                  </a>{" "}
                </li>
              </ul>
              <p className="copyrights mt-2 mb-2">
                &copy; 2023 CodeFlex. 
                
              </p>
            </div>
          </div>
        </div>
      </footer>
            
      <div id="animated-cursor">
        <div id="cursor">
          <div id="cursor-loader"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
